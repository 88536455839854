





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ImageExpansion extends Vue {
  private src: string | null = null

  private show = false

  get showState(): { [key: string]: string } {
    return {
      display: this.show ? 'block' : 'none',
    }
  }

  private showImage(src: string): void {
    this.src = src
    this.show = true
  }

  private hideImage(): void {
    this.src = null
    this.show = false
  }
}
