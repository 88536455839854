

























import { Component, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ImageExpansion from '@/components/atoms/ImageExpansion.vue'
import StudyRouting from '@/views/student/study/StudyRouting.vue'

@Component({
  components: {
    ButtonBase,
    ImageExpansion,
  },
})
export default class StudyExplanation extends Mixins(StudyRouting) {
  get contentSource(): string {
    return '/student/study/explanation/explanation-' + this.$route.params.id
  }
}
